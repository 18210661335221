<template>
  <player-io-detail-card />
</template>
<script>
import PlayerIoDetailCard from "./components/PlayerIoDetailCard.vue";
export default {
  components: {
    PlayerIoDetailCard,
  },
};
</script>
